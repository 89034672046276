import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import axios from "axios"
import LoadingIcon from '../svg/LoadingIcon'

const generalStyle = {
	maxWidth: '100%',
	outline: 'none',
	appearance: 'none',
	width: '100%',
	resize: 'none',
}

const getSlug = (str) => {
	str = str.replace(/\s+/g, '-').toLowerCase();

	return str
}

const rowStyle = [
	css`
		+ * {
		  margin-top: 1rem;
		}
	`,
]



const checkIcon = [
	css`
	  position: absolute;
	  top: 0;
	  z-index: 40;
	  left: 1rem;
	  bottom: 0;
	  right: auto;
	  margin: auto;
	  height: 22px;
	  width: 22px;
	  border-radius: 50%;
	  background-color: transparent;
	  border: 1px solid rgba(0,0,0,0.15);

  &:after {
	  content: "";
	  position: absolute;
	  display: none;
	  left: 8px;
	  top: 4px;
	  width: 5px;
	  height: 10px;
	  border: solid white;
	  border-width: 0 2px 2px 0;
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  transform: rotate(45deg);
  }

	`
]

const checkboxStyle = [
	css`
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	  z-index: 50;
	  width: 100%;
	  height: 100%;

	  &:checked ~ .checkmark {
		  background-color: var(--color-primary);
		  border: 1px solid var(--color-primary);
	  }

	  &:checked ~ .checkmark:after {
		  display: block;
	  }
	`
]



const Input = ( {type, name, icon, placeholder, className, width} ) => {

	const [isChanged, setIsChanged] = useState(false);

	const toggleInput = (event) => {
	 if (event.target.length === 0) {
	 	setIsChanged(false);
	} else {
		setIsChanged(true);
	}


   };

	return (
		<div className={`px-2 md:px-3 lg:px-4 relative ${width}`}>
		<label htmlFor={getSlug(name)} className="relative">
			<span className="block text-xs mb-1 font-semibold">{name}</span>

			<input id={getSlug(name)} type={type} name={getSlug(name)} placeholder={placeholder} className={`${className} bg-white border-b ${isChanged ? 'border-primary-default' : 'border-gray-200' } h-10 p-3 ${icon ? 'pl-7' : 'pl-3'} `} style={generalStyle} onChange={toggleInput}  />
		</label>
		{icon &&
			<span className={`icon-${icon} absolute left-2 md:left-3 lg:left-4 right-auto bottom-0 top-10 my-auto text-black h-6 w-6`} />
		}
		</div>
	)
}

Input.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
	icon: PropTypes.string,
}

Input.defaultProps = {
	type: `text`,
	name: `Input`,
	placeholder: null,
	className: `input`,
	width: `flex-1`,
	icon: ``,
}

const TextArea = ( {name, placeholder, rows, className, width} ) => {

	const [isChanged, setIsChanged] = useState(false);

	const toggleTextarea = (event) => {
	 if (event.target.length === 0) {
	 	setIsChanged(false);
	} else {
		setIsChanged(true);
	}


   };



	return (
		<div className={`px-2 md:px-3 lg:px-4 ${width}`}>
			<label htmlFor={getSlug(name)}><span className="block text-xs mb-1 font-semibold">{name}</span></label>
			<textarea id={getSlug(name)} name={getSlug(name)} placeholder={placeholder} rows={rows} className={`${className} bg-white border ${isChanged ? 'border-primary-default' : 'border-gray-200'} p-3`} style={generalStyle} onChange={toggleTextarea} />
		</div>

	)
}

TextArea.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	rows: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
}

TextArea.defaultProps = {
	name: `Input`,
	placeholder: null,
	rows: `10`,
	className: `text-input`,
	width: `flex-1`,
}

const AcceptBox = ( {type, name, placeholder, className, width, children, ...rest} ) => {


	 const [isChecked, setIsChecked] = useState(true);

	 const toggleChecked = () => {
	  setIsChecked(!isChecked);
	};

	return (
		<div className={`px-2 md:px-3 lg:px-4 ${width}`} {...rest}>
		<label className="px-5 mb-2 flex justify-start items-center flex-row relative" htmlFor={getSlug(name)}>
			<span className="block uppercase font-secondary mb-2 font-medium hidden ">{name}</span>

			<input id={getSlug(name)} type={type} name={getSlug(name)} className={`${className}`} css={[
				checkboxStyle
			]} checked={isChecked}
                onChange={toggleChecked} />
		<span className="checkmark" css={[
				checkIcon
			]} ></span>
			<div
				className="text-xs leading-tight font-normal font-secondary  tracking-wide pl-9 relative z-50"
				style={{lineHeight: '168%'}}
				>
				{children}
				</div>
		</label>
		</div>
	)
}

AcceptBox.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
	children: PropTypes.node.isRequired,
}

AcceptBox.defaultProps = {
	type: `checkbox`,
	name: `Checkbox`,
	placeholder: null,
	className: `input`,
	width: `flex-1`,
	children: `By leaving this box checked, you are accepting the terms detailed in our Privacy Policy.`,
}

const CheckBox = ( {type, name, className, width, label, ...rest} ) => {

	return (
		<div className={`px-2 md:px-3 lg:px-4 ${width}`}>
		<label className="px-5 mb-2 -mx-4 flex justify-start items-center flex-row relative" htmlFor={getSlug(name)}>
			<span className="block uppercase text-xs mb-2 font-semibold hidden">{name}</span>

			<input id={getSlug(name)} type={type} name={getSlug(name)} className={`${className}`} css={[
				checkboxStyle
			]} {...rest} />
		<span className="checkmark" css={[
				checkIcon
			]} ></span>
			<div
				className="text-xs font-semibold tracking-wide pl-6"
				style={{lineHeight: '168%'}}
				dangerouslySetInnerHTML={{__html: label}}
				/>
		</label>
		</div>
	)
}

CheckBox.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
	label: PropTypes.object,
}

CheckBox.defaultProps = {
	type: `checkbox`,
	name: `Checkbox`,
	className: `input`,
	width: `flex-1`,
	label: ``,
}


const Heading = ( {children, ...rest} ) => {
	return (

		<div className="w-full max-w-full px-2 md:px-3 lg:px-4" {...rest}>

			<h4

			className="text-black mb-3 mt-3"
			>
			{children}
			</h4>

		</div>
	)
}

const Submit = ( {children, ...rest} ) => {
	return (
		<div {...rest}>
		<input type="submit" className={`font-semibold transition-all duration-200 w-full h-16 relative bg-primary-default hover:bg-primary-darker text-white`} value={children} />
		</div>
	)
}

Submit.propTypes = {
  children: PropTypes.node.isRequired,
}

Submit.defaultProps = {
	children: `Send Message`,
}

const Row = ( {children} ) => {
	return (
		<div className="flex flex-row justify-start flex-wrap -mx-2 md:-mx-3 lg:-mx-4"
		css={[
			rowStyle
		]}
		>
			{children}
		</div>
	)
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
}

export class Form extends React.Component {
	static Input = Input;
	static TextArea = TextArea;
	static Row = Row;
	static AcceptBox = AcceptBox;
	static CheckBox = CheckBox;
	static Heading = Heading;
	static Submit = Submit;

	constructor(props) {
	    super(props);
	     this.state = {
			submitting: false,
  		    status: null
	    };
	  }

	render() {
		const {children, postUrl, ...rest} = this.props;

		  const handleServerResponse = (ok, msg, form) => {

			  this.setState({
				  submitting: false,
			      status: { ok, msg }
			  })

		    if (ok) {
		      form.reset();
		    }
		  };

		  const handleOnSubmit = e => {
		    e.preventDefault();
		    const form = e.target;

			this.setState({
				submitting: true
			})
		    axios({
		      method: "post",
		      url: postUrl,
		      data: new FormData(form)
		    })
		      .then(r => {
		        handleServerResponse(true, "Thanks for reaching out.", form);
		      })
		      .catch(r => {
		        handleServerResponse(false, r.response.data.error, form);
		      });
		  };
		
		const midSubmitStyle = [
			css`
				
				pointer-events: none;
				user-select: none;
				
				> * {
					opacity: 0.5;
				}
			`
		]

		return (
			<div style={{position: 'relative'}}>
				<form {...rest} onSubmit={handleOnSubmit} css={[
				this.state.submitting && midSubmitStyle
			]}>
				{children}

				{this.state.status && (
					<div className="pt-4 text-center text-sm 2xl:text-base">
						<span className={`${!this.state.status.ok ? "errorMsg" : ""} font-medium tracking-normal`}>
							{this.state.status.msg}
						</span>
					</div>
					)}
			</form>
			{this.state.submitting &&
					<LoadingIcon className={`absolute inline-block top-0 right-0 left-0 bottom-0 m-auto z-10 !h-48 !w-48`} />
				}
			</div>
		)
	}
}

Form.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([Input, TextArea, AcceptBox, Row, CheckBox, Heading, Submit])
    })
  ),
  postUrl: PropTypes.string,
};

Form.defaultProps = {
	postUrl: `#`,
}

export default Form;
