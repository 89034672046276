import React from 'react'
import Layout from '../components/layout'
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
import CoverImage from '../components/CoverImage'
import Section from '../components/Section'
import { StaticImage } from "gatsby-plugin-image"
import Hours from '../components/Hours'
// import ContactForm from '../components/ContactForm'
import SimpleContactForm from '../components/SimpleContactForm'
import Button, { ButtonGroup } from '../components/Button'
import { availabilityData } from "../data/availability-data"

const ContactPage = ( {location} ) => {

	// const getParam = location.search

	// const [forAppt, setForAppt] = useState(false);

	// const [forAppt, setForAppt] = useState(
	// 	getParam.includes('book_appt') ? ( true ) : ( false )
	// );

	// const toggleAppt = () => setForAppt(forAppt => !forAppt);

	const { spaceAvailable } = availabilityData[0]

	return (
		<Layout location={location}>
		{/* <Seo title="Contact" /> */}
		<CoverImage crop={true}>
			<StaticImage

				src={`../images/stock/casak9-z7rcwqCi77s.jpg`}
				alt="Casa Canine"
				loading={`eager`}
				/>
		</CoverImage>

			<Section className="-mt-10" vAlign="items-start">
				<Section.Col className="md:flex-2/5 md:max-w-2/5">
					<Section.Content
						header="Get in touch"
						as={`h2`}
						// preheader="New clients welcome"
						preheader={spaceAvailable === true ? 'New client welcome' : null}
						content={
							`
							<p>I look forward to hearing from you soon!<br/><small>– Steve Grover @ CasaCanine</small></p>
							`
						}
						>
						<ButtonGroup>
							<Button href="tel:6132614140" type={`a`} title={`Call Us at (613) 261-4140`}>Call Us</Button>
							<Button variation={`outline`} type={`external`} href={`https://casacanine.propetware.com/`} 
							title={`Book Now`}>Book Now</Button>
						</ButtonGroup>
						
					</Section.Content>
					<Hours
						heading={`Pickup & Dropoff Hours`}
						className="mt-10 text-xs leading-relaxed" shortForm={false} />
				</Section.Col>
				<Section.Col className="flex-trueauto">
					<SimpleContactForm 
					className="lg:-mt-[18rem] md:-mt-56 relative z-40" 
					postheader={spaceAvailable === true ? null : <p>*At this time, we are <strong>not able</strong> to accomodate any new clients. </p>}
					/>
				</Section.Col>
			</Section>
		</Layout>

	)
}

export const Head = () => <Seo2 title={`Contact`} />

export default ContactPage