import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Form from './Form'


const formStyle = {
  // boxShadow: 'rgba(251, 171, 4, 0.4) -5px 5px, rgba(251, 171, 4, 0.3) -10px 10px, rgba(251, 171, 4, 0.2) -15px 15px, rgba(251, 171, 4, 0.1) -20px 20px, rgba(251, 171, 4, 0.05) -25px 25px',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
}


const SimpleContactForm = ( {className, header = "Contact Us", postheader = null}) => {

	return (
    <div className={`max-w-2xl w-full mx-auto ${className}`} style={formStyle}>
    <div className="flex flex-col pt-10 px-10 pb-4 border-b-4 border-primary-default rounded-tr-sm rounded-tl-sm bg-black text-white">
    {header ? <h2>{header}</h2> : null}
	{postheader ? <div className={`flex-full -mt-2 text-primary-lighter`}>{postheader}</div> : null}
    </div>
		<Form className="w-full mx-auto p-12 pt-8 bg-white relative" id="form" postUrl={`https://media.fairfld.com/wp-json/contact-form-7/v1/contact-forms/11/feedback`}>

			<Form.Row>
      			<Form.Heading>Your information</Form.Heading>
				<Form.Input type="text" name="First Name" width="flex-1/2 w-1/2" />
				<Form.Input type="text" name="Last Name" width="flex-1/2 w-1/2" />
			</Form.Row>
      		<Form.Row>
				<Form.Input type="email" name="Email" width="flex-7/12 max-w-7/12" icon="envelope" />
        		<Form.Input type="tel" name="Phone Number" width="flex-5/12 max-w-5/12" icon="phone" />
				<input type="text" name="company-name" style={{visibility: 'hidden', opacity: '0', position: 'absolute', top: '0', left: '0', zIndex: '-1'}} autoComplete={`off`} />
			</Form.Row>
			<Form.Row>
  				<Form.TextArea name="Message" width="w-full" rows="8" />
  			</Form.Row>
			
      <Form.Submit>
        Send Message
      </Form.Submit>
			<Form.Row>
				<Form.AcceptBox name="Acceptance" width="w-full" style={{marginTop: '1.5rem'}}>
        By leaving this box checked, you are accepting the terms detailed in our <Link className="text-primary-default no-underline border-b-1 relative z-50" to={`/privacy-policy`}>Privacy Policy</Link>.
        </Form.AcceptBox>
			</Form.Row>
		</Form>
    </div>
	)
}

SimpleContactForm.propTypes = {
	className: PropTypes.string,
}

SimpleContactForm.defaultProps = {
	className: ``,
}


export default SimpleContactForm
